import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import HukumSection from '../components/HukumSection'
import HukumCategoriesNav from '../components/HukumCategoriesNav'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = hukum => {
  const now = Date.now()
  return hukum.filter(post => Date.parse(post.tahun) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (hukum, title, contentType) => {
  const isCategory = contentType === 'produkHukum'
  const byCategory = post =>
    post.categories &&
    post.categories.filter(cat => cat.category === title).length
  return isCategory ? hukum.filter(byCategory) : hukum
}

// Export Template for use in CMS preview
export const HukumIndexTemplate = ({
  title,
  subtitle,
  featuredimage,
  hukum = [],
  produkHukum = [],
  enableSearch = true,
  contentType
}) => (
  <Location>
    {({ location }) => {
      let filteredHukum =
        hukum && !!hukum.length
          ? byCategory(byDate(hukum), title, contentType)
          : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredHukum = filteredHukum.filter(post =>
          post.frontmatter.title.toLowerCase().includes(searchTerm)
        )
      }

      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subtitle}
          />

          {!!produkHukum.length && (
            <section className="section thin">
              <div className="container">
                <HukumCategoriesNav enableSearch categories={produkHukum} />
              </div>
            </section>
          )}

          {!!hukum.length && (
            <section className="section">
              <div className="container">
                <HukumSection hukum={filteredHukum} />
              </div>
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

HukumIndexTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

// Export Default BlogIndex for front-end
const HukumIndex = ({ data: { page, hukum, produkHukum } }) => (
  <Layout>
    <HukumIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      hukum={hukum.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      produkHukum={produkHukum.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)



export default HukumIndex

export const pageQuery = graphql`

  query HukumIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      fields {
        contentType
      }
      frontmatter {
        title
        templateKey
        subtitle
      }
    }

    hukum: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "hukum" } } }
      sort: { order: DESC, fields: [frontmatter___tahun] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tahun
            link
            pdf
            categories {
              category
            }
          }
        }
      }
    }
    produkHukum: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "produkHukum" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }

`
